import moment from 'moment'
import 'owl.carousel'


jQuery(document).ready(function ($) {

  // VIDEO CAROUSEL
  function createVideoCarousel() {
    const videoCarousel = $("#video-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    videoCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      margin: 55,
      center: true,
      items: 1,
      responsive: {
        0: {
        },
        700: {
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createVideoCarousel()

  // DISPLAY VIDEO
  $("#found").on('click', function () {
    $("#hidden-video").toggleClass("active");
  });

  // NAVIGATION
  $("#hero").addClass("active");
  $("#mobile-nav-menu").addClass("anchor-mobile-hero");

  // Function to toggle classes for navigation items
  function toggleNavigation(activeElement) {
    $("#mobile-nav-menu").toggleClass("anchor-mobile-hero", activeElement === "#hero");
    $("#hero").toggleClass("active", activeElement === "#hero");
    $("#music").toggleClass("active", activeElement === "#music");
    $("#video").toggleClass("active", activeElement === "#video");
    $("#email").toggleClass("active", activeElement === "#email");
    $("#boy").toggleClass("active", activeElement === "#boy");
    $("#tour").toggleClass("active", activeElement === "#tour");
  }

  // Event listeners for navigation items
  $(".set-hero").on('click', function () {
    toggleNavigation("#hero");
  });

  $(".set-music").on('click', function () {
    toggleNavigation("#music");
  });

  $(".set-video").on('click', function () {
    toggleNavigation("#video");
  });

  $(".set-email").on('click', function () {
    toggleNavigation("#email");
  });

  $(".set-boy").on('click', function () {
    toggleNavigation("#boy");
  });

  $(".set-tour").on('click', function () {
    toggleNavigation("#tour");
  });

  $("#luke-hemmings-newsletter-signup-us-arista").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        alert("Submitted!");
      },
      error: function (err) {
        // Do things when submission has an error.
        alert("An error has occured!");
      }
    });
  });

  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_1670562/events?app_id=45PRESS_luke_hemmings',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            console.log(offer.type)
            if (offer.type === 'Tickets') {
              html += '<a href="' + offer.url + '" target="_blank" class="link">' + offer.type.toUpperCase() + '</a>';
            }
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">No Upcoming Shows!</span>');
      }
    }
  });
});